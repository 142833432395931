import { Device, Topic } from "./Device";

const BASE_URL = 'https://yz7baphdmk.execute-api.us-east-1.amazonaws.com'

interface ResponseDevice {
    uuid: string
    uniqueId: string
    qcData: {[appId: string]: boolean}
}

interface ResponseVerifyData {
    GNSS: boolean;
    EMERGENCY: boolean;
    HEARTRATE: boolean;
    ACTIVITY: boolean;
    IMPACT: boolean;
}

export async function GetAllDeviceVerifiedData(accessToken: string): Promise<Device[] | undefined> {
    let devices = await GetDevices(accessToken)

    if (devices == null) {
        return undefined
    }

    let result: Device[] = []
    
    for (let device of devices) {
        console.log(device)
        const topicSet: Set<Topic> = new Set(); 

        for (let key in device.qcData) {
            let ok = device.qcData[key];

            if (key as keyof ResponseVerifyData && ok) {
                topicSet.add(key as Topic);
            }
        }
        
        result.push({
            UUID: device.uuid, 
            numericID: Number(device.uniqueId),
            verified: topicSet
        })
    }

    return result
}


export async function GetDevices(accessToken: string): Promise<ResponseDevice[] | null> {
    const apiUrl: string = `${BASE_URL}/prod/devices/qc`;

    const request: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
    };

    try {
        const response = await fetch(apiUrl, request);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const data: ResponseDevice[] = await response.json();
        return data

    } catch (error) {
        return null
    }
}

export async function VerifyDeviceData(accessToken: string, deviceId: string): Promise<ResponseVerifyData | null> {
    const apiUrl: string = `${BASE_URL}/prod/devices/qc?deviceid=${deviceId}`;

    const request: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
    };

    try {
        const response = await fetch(apiUrl, request);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const data: ResponseVerifyData = await response.json();
        
        return data

    } catch (error) {
        return null
    }
}