import { CognitoUserPool, ICognitoUserPoolData } from 'amazon-cognito-identity-js';

// these are not inherently secrets as they only refer to resources
let uid = process.env.REACT_APP_USER_POOL_ID || "us-east-1_12ChUpg6F"
let cid = process.env.REACT_APP_CLIENT_ID || "2dhpaaaup9tdg4d48qpna51hn6"

const userPool: ICognitoUserPoolData = {
    UserPoolId: uid,
    ClientId: cid,
};

export default new CognitoUserPool(userPool);
  