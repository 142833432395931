export interface Device {
    numericID: number;
    UUID: string
    verified: Set<Topic>
}

export enum Topic {
    HEARTRATE = "HEARTRATE", 
    IMPACT = "IMPACT", 
    EMERGENCY = "EMERGENCY", 
    GNSS = "GNSS", 
    ACTIVITY = "ACTIVITY"
}

export const Topics : Topic[] = [
    Topic.ACTIVITY,
    Topic.GNSS,
    Topic.EMERGENCY,
    Topic.IMPACT,
    Topic.HEARTRATE,
]

export interface Event {
    timestamp: Date;
    value ?: any;
}