import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import UserPool from './UserPool';

export const authenticate = async (Username: string, Password: string): Promise<CognitoUserSession> => {
    return new Promise<CognitoUserSession>(function(resolve, reject) {
        const user = new CognitoUser({
            Username:Username,
            Pool:UserPool
        });
    
        const authDetails= new AuthenticationDetails({
            Username:Username,
            Password
        });
    
        user.authenticateUser(authDetails,{
            onSuccess:(session, userConfirmationNecessary)=>{
                let idtoken = session.getIdToken()
                resolve(session)
            },
            onFailure:(err: any)=>{
                reject(err)
            }
        });
    })
};

export const Logout = () => {
    const user = UserPool.getCurrentUser();
    user?.signOut();
    window.location.href = '/';
};