import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Home'
import Login from './Login';
import { PreventAndDeloza } from './Pnd';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />}/>
        <Route path='/home' element={<Home />}/>
        <Route path="/pnd" element={<PreventAndDeloza/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
