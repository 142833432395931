import React, { useState } from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { authenticate } from './Auth';
import logo from './logo.svg';
import './Home.css';

const Login = () => {

  const Navigate = useNavigate();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();    
    await authenticate(username, password)
    Navigate('/home');
  };

  return (
    <React.Fragment>
      <header className="Home-header"/>
      <body className="Home-body">
        <img src={logo} className="Home-logo" alt="logo" />
        <hr className="mt-5 mb-5"/>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicUsername">
            <Form.Label>User name</Form.Label>
            <InputGroup hasValidation>
              <FormControl
                type="username"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <InputGroup hasValidation>
              <FormControl
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                />
            </InputGroup>
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <hr className="mt-5 mb-5"/>
        <h5>Contact <a href="mailto:support@wrlds.com">support@wrlds.com</a> for access</h5>
      </body>
    </React.Fragment>
  )
}

export default Login