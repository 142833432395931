import logo from './logo.svg';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import { Logout } from './Auth';
import { CheckCurrentUser, GetUsername } from './User';

function Home() {
  const Navigate = useNavigate();
  CheckCurrentUser()

  const signOut = () => {
    Logout()
  }
    
  return (
    <div className="Home">
    <header className="Home-header"/>
    <body className="Home-body" >
      <img src={logo} className="Home-logo" alt="logo" />
      <Stack direction="horizontal" gap={2} className="justify-content-center">
        <hr className="mt-2 mb-2"/>

        <Stack className="Home-buttons" direction="vertical" gap={4}>
          <h5>Welcome {GetUsername()}</h5>
          <Button style={{ width: '400px' }} variant="secondary" onClick={() => {Navigate('/pnd')}}>
            Prevent and Deloza Devices
          </Button>
          <Button style={{ width: '400px' }} variant="outline-primary" onClick={() => {signOut()}}>
            Signout
          </Button>
        </Stack>
      </Stack>
    </body>
    </div>
  )
}
export default Home