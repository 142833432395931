import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserPool from "./UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";


export const GetUsername = (): string  => {
    const user = UserPool.getCurrentUser();
    return user?.getUsername() || ""
}

export const CheckCurrentUser = ():  CognitoUser | null => {
    const Navigate = useNavigate();
    const Location = useLocation();

    let user = UserPool.getCurrentUser();
    
    useEffect(()=> {
        if(user){
            if (Location.pathname === '/') {
                Navigate('/home')
            }
        } else {
            Navigate('/')
        }
    },[Navigate]);

    return user
  }