import React, { useEffect, useState } from 'react'
import { CheckCurrentUser } from './User'
import { Button, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Logout } from './Auth'
import pndlogo from './pnd.svg';
import { Device, Topics } from './Device'
import { GetAllDeviceVerifiedData } from './Api'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import {InfinitySpin} from 'react-loader-spinner';

export const PreventAndDeloza = () => {
    const Navigate = useNavigate();
    let user = CheckCurrentUser()
    
    const [devices, setDevices] = useState<Device[]>([]);
    const [loading, isLoading] = useState(true);    
    
    useEffect(() => {
        user?.getSession((async (error: Error, session: CognitoUserSession | null) => {
            if (error != null || user == null || session == null) {
                isLoading(false);
                return
            }

            let idToken = session!.getIdToken()

            isLoading(true);
            let fetchedDevices = await GetAllDeviceVerifiedData(idToken.getJwtToken())

            if (fetchedDevices == undefined) {
                return null
            }
            
            fetchedDevices.sort((b, a) => a.numericID - b.numericID);

            setDevices(fetchedDevices)

            isLoading(false);
        }))
    }, [])

    const signOut = () => {
      Logout()
    }

    return (
        <React.Fragment>
            <header className="Home-header"/>
            <body className="Home-body">
                <img src={pndlogo} className="Home-logo" alt="logo" />
                {loading ? <InfinitySpin />:

                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                            <th scope="col"></th>
                            {
                                Topics.map((topic, i) => {
                                    return <th scope="col" key={i}>{topic}</th>
                                })
                            }
                            </tr>
                        </thead>
                        <tbody>
                                {
                                    devices.map((device, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <tr>
                                                    <th scope="row">
                                                        {device.numericID}
                                                    </th>
                                                    {Topics.map((topic, i) => (
                                                        device.verified.has(topic) ?
                                                        <th scope="col" key={i}>✅</th> :
                                                        <th scope="col" key={i}>❌</th> 
                                                    ))}
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                        </tbody>
                    </table>
                }

                <Stack direction="horizontal" gap={2} className="justify-content-center">
                    <hr className="mt-2 mb-2"/>
                    <Stack className="Home-buttons" direction="vertical" gap={4}>
                    <Button style={{ width: '400px' }} variant="secondary" onClick={() => {Navigate('/home')}}>
                        back
                    </Button>
                    <Button style={{ width: '400px' }} variant="outline-primary" onClick={() => {signOut()}}>
                        Signout
                    </Button>
                    </Stack>
                </Stack>
            </body>
        </React.Fragment>
    )
}